


export default function CV() {
    return(
        <div className="p-12">
            <p className="black text-5xl text-left mb-2">WHO I'VE WORKED WITH</p>
            <p className="text-left text-2xl mb-12 light">These are some of the companies I've worked with recently.</p>
        <div className="text-left md:grid md: grid-cols-2 md:gap-8 ">
            <div className="border-b-2 border-black mb-12 md:mb-4">
                <p className="black text-2xl">WRKSYNC</p>
                <p className="light text-lg">FullStack Developer & CTO</p>
                <p className="semi my-4">MAY 23 - NOW</p>
            </div>
            <div className="border-b-2 border-black mb-12 md:mb-4">
                <p className="black text-2xl">EEIP</p>
                <p className="light text-lg">Frontend Developer & Integrations Specialist</p>
                <p className="semi my-4">OCT 21 - MAY 23</p>
            </div>
            <div className="border-b-2 border-black mb-12 md:mb-4">
                <p className="black text-2xl">PAMOGI</p>
                <p className="light text-lg">FullStack Developer</p>
                <p className="semi my-4">NOV 22 - MAY 23</p>
            </div>
            <div className="border-b-2 border-black mb-12 md:mb-4">
                <p className="black text-2xl">FREELANCE DEVELOPER</p>
                <p className="light text-lg">FullStack Developer</p>
                <p className="semi my-4">MAR 17 - MAR 21</p>
            </div>
        </div>
        </div>
    )
}