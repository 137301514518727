import wslogo from './images/projects/wslogo.png'
import pamlogo from './images/projects/pamlogo.png'
import stologo from './images/projects/stologo.png'
import wetlogo from './images/projects/wetlogo.png'
import r3slogo from './images/projects/r3slogo.png'
import cirlogo from './images/projects/cirlogo.png'
import kullogo from './images/projects/kullogo.png'
export default function Projects() {

    return(
        <div className="p-8 mt-12">
            <div className="">
                <div>
                    <div>
                    <p className="black text-5xl text-left mb-2">PROJECTS</p>
                    <p className="text-left text-2xl mb-12 light">A collection of projects I've worked on.</p>
                    </div>
                    <div className="md:grid md:grid-cols-3 gap-4">
                        <div>
                            <div className='bg-black p-0 rounded-xl shadow-xl text-[#F5F5F7]'>
                                <img className='' src={wslogo} />
                            </div>                            
                            <div className='px-2 mt-4 mb-8 md:mb-0 text-left flex justify-between'>
                                <div>
                                    <p className='semi text-lg justify-between'>WRKSYNC</p>
                                    <p className='light'>Web App</p>
                                </div>
                                <div>
                                    <a target='_blank' href='https://www.wrksync.com/' className='bold px-2 text-xs pt-[5px] pb-[2px] bg-black text-[#F5F5F7] rounded-full'>Visit</a>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div className='bg-black p-0 rounded-xl shadow-xl text-[#F5F5F7]'>
                                <img className='p-6' src={pamlogo} />
                            </div>
                            <div className='mb-8 md:mb-0 p-2 mt-4 text-left flex justify-between'>
                                <div>
                                    <p className='semi text-lg justify-between'>PAMOGI </p>
                                    <p className='light'>Metaverse</p>
                                </div>
                                <div>
                                <a target='_blank' href='https://pamogi.com' className='bold px-2 text-xs pt-[5px] pb-[2px] bg-black text-[#F5F5F7] rounded-full'>Visit</a>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='bg-black p-0 rounded-xl shadow-xl text-[#F5F5F7]'>
                                <img className='p-6' src={stologo} />
                            </div>
                            <div className='mb-8 md:mb-0 p-2 mt-4 text-left flex justify-between'>
                                <div>
                                    <p className='semi text-lg justify-between'>STORMING </p>
                                    <p className='light'>EU Project</p>
                                </div>
                                <div>
                                <a target='_blank' href='https://storming-project.eu/' className='bold px-2 text-xs pt-[5px] pb-[2px] bg-black text-[#F5F5F7] rounded-full'>Visit</a>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div className='bg-black p-0 rounded-xl shadow-xl text-[#F5F5F7]'>
                                <img className='p-6' src={kullogo} />
                            </div>
                            <div className='mb-8 md:mb-0 p-2 mt-4 text-left flex justify-between'>
                                <div>
                                    <p className='semi text-lg justify-between'>KULANIKINIS </p>
                                    <p className='light'>Shopify</p>
                                </div>
                                <div>
                                <a target='_blank' href='https://kulanikinis.com' className='bold px-2 text-xs pt-[5px] pb-[2px] bg-black text-[#F5F5F7] rounded-full'>Visit</a>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div className='bg-black p-0 rounded-xl shadow-xl text-[#F5F5F7]'>
                                <img className='p-6' src={r3slogo} />
                            </div>
                            <div className='mb-8 md:mb-0 p-2 mt-4 text-left flex justify-between'>
                                <div>
                                    <p className='semi text-lg justify-between'>R3SET </p>
                                    <p className='light'>Shopify</p>
                                </div>
                                <div>
                                <a target='_blank' href='https://r3set.com' className='bold px-2 text-xs pt-[5px] pb-[2px] bg-black text-[#F5F5F7] rounded-full'>Visit</a>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div className='bg-black p-0 rounded-xl shadow-xl text-[#F5F5F7]'>
                                <img className='p-6' src={cirlogo} />
                            </div>
                            <div className='mb-8 md:mb-0 p-2 mt-4 text-left flex justify-between'>
                                <div>
                                    <p className='semi text-lg justify-between'>CIRCULARPSP </p>
                                    <p className='light'>EU Project</p>
                                </div>
                                <div>
                                <a target='_blank' href='https://circularpsp.eu/' className='bold px-2 text-xs pt-[5px] pb-[2px] bg-black text-[#F5F5F7] rounded-full'>Visit</a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}