import Hero from './Hero'
import Projects from './Projects'
import About from '../About'
export default function Graph() {
    return(
        <div>
            <Hero />
            <Projects />
            <About />
        </div>
    )
}