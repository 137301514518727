
import Profile from '../images/profile.png'



export default function Hero() {

    return(
        <div className="text-left p-8">
            <div className="mt-12 md:text-[8rem] text-4xl leading-tight scroll">
                <p className="black">BUILDING IDEAS</p>

            </div>
            <div>
                <div className="rounded-xl bg-black shadow-xl flex justify-between items-end">
                    <div className="text-[#F5F5F7] m-12">
                        <p className="bold text-2xl">Erick Agullo</p>
                        <p className="regular text-lg mb-8">Graphic & UX/UI Designer</p>
                        <p className="regular">
                            <a target="_blank" href="https://www.linkedin.com/in/erick-agullo-7b8b9b1b0">
                                <u>Connect on LinkedIn</u>
                            </a>
                        </p>
                        <p className="regular">
                            <a target="_blank" href="mailto:eragulop@gmail.com">
                                <u>eragulop@gmail.com</u>
                            </a>
                        </p>

                    </div>
                    <img className="rounded-xl md:block hidden" src={Profile} />
                </div>
            </div>
        </div>
    )
}