//solar img
import cslogoblue from '../images/solar/logo-blanco-fondo-azul.png'
import cslogoorange from '../images/solar/logo-blanco-fondo-naranja.png'
import cslogoblack from '../images/solar/logo-blanco-fondo-negro.png'
import csflyer from '../images/solar/solarflyer.png'
import cscard2 from '../images/solar/solarcard2.png'
import sccard from '../images/solar/solarcard.png'
//red img
import redlogo from '../images/redonda/redondalogo.png'
import redmock from '../images/redonda/redmock.png'
import pizzamock from '../images/redonda/pizzamock.png'
//alam img
import alalogo1 from '../images/alameda/logo-a0-green.png'
import alalogo2 from '../images/alameda/logo-a0-yellow.png'
import alamqueda from '../images/alameda/queda.png'
import alabanco from '../images/alameda/alameda-banco-hori.png'
//lope
import lopesign from '../images/lope/lope-sign.png'
import lopeflyer from '../images/lope/lope-flyer.png'
//grainz
import grainzlogo from '../images/le.png'
export default function Projects() {

    return(
        <div className="p-8 mt-12">
            <div className="">
                <div>
                    <div>
                    <p className="black text-5xl text-left mb-2">PROJECTS</p>
                    <p className="text-left text-2xl mb-12 light">A collection of projects I've worked on.</p>
                    </div>
                </div>

                <div>
                    <div className="grid grid-cols-2 gap-2">
                        <img src={cslogoorange} />
                        <img src={cslogoblue} />
                        <img  src={cslogoblack} />
                        <img src={csflyer} />
                        <img src={cscard2} />
                        <img src={redmock} />
                        <img src={redlogo} />
                        <img src={alalogo1} />
                        <img src={alalogo2} />
                        <img src={alamqueda} />
                        <img src={alabanco} />
                        <img src={lopesign} />
                        <img src={lopeflyer} />
                        <img src={grainzlogo} />
                    </div>
                </div>
            </div>
        </div>
    )
}