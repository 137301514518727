import logo from './logo.svg';
import './App.css';
import Header from './Header';
import Hero from './Hero';
import About from './About';
import Projects from './Projects';
import CV from './CV';
function App() {
  return (
    <div className="App md:px-12 mb-24 ">
      <Hero />
      <CV />
      <Projects />
      <About />
    </div>
  );
}

export default App;
