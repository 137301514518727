import map from './images/projects/map.png'

export default function About() {
    return(
       
        <div className='p-8 text-black'>
            <p className="black text-5xl text-left mb-2">CONTACT</p>
            <p className="text-left text-2xl mb-12 light">Get in touch to create something great together.</p>
           <div>
            <div className='text-left md:grid md:grid-cols-2 md:gap-6'>
                <div className='mb-12'>
                    <img className='rounded-xl shadow-xl' src={map} />
                </div>
                <div className='regular text-2xl'>
                    <p className='mb-4 semi'>📍 Based in La Línea</p>
                    <a target='_blank' href='mailto:eragulop@gmail.com'>
                        <u><p className='mb-4'>📨 eragulop@gmail.com</p></u>
                    </a>
                    <a target='_blank' href='https://www.linkedin.com/in/erick-agullo-7b8b9b1b0'>
                        <u><p className='mb-4'>👥 LinkedIn</p></u>
                    </a>
                    <a target='_blank' href='https://github.com/Ericksonxx'>
                        <u><p className='mb-4'>🧑🏻‍💻 GitHub</p></u>
                    </a>
                    <a target='_blank' href='https://devdojo.com/erickagulopez'>
                        <u><p className='mb-4'>✍🏻 Blog</p></u>
                    </a>
                </div>
            </div>
           </div>
        </div>
    )
}