import React from "react";
import Marquee from "react-fast-marquee";

export default function MarqueeComponent () {
  return(
    <Marquee className="light text-2xl mb-12 px-8">
        <p className="mr-2 ml-4 semi">Tech Stack: </p>
        <p className="mr-2">JavaScript - </p>
        <p className="mr-2 semi">React - </p>
        <p className="mr-2">TypeScript - </p>
        <p className="mr-2 semi">Nextjs - </p>
        <p className="mr-2">Nodejs - </p>
        <p className="mr-2 semi">Tailwind - </p>
        <p className="mr-2">Shopify - </p>
        <p className="mr-2">MUI - </p>
        <p className="mr-2 semi">CSS - </p>
        <p className="mr-2">GraphQL - </p>
        <p className="">Restful API </p>
    </Marquee>
  )
  }